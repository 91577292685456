$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
.Home {

  width: 100vw;
  height: 100vh;
  min-height: 720px;

  //padding-bottom: 200vh;

  &Img {
    //width: 100vw;
    width: auto;
    height: 100vh;
    min-height: 720px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    //background-image: url($cdnURL + "/newassets/main-background.jpg");
    background-image: url($cdnURL + "/newassets/main-background.jpg"), linear-gradient(90deg, rgba(99,68,91,1) 33%, rgba(249,172,128,1) 100%);
  }

  &Video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  //display: flex;
  //justify-content: center;
  //align-items: center;

  //background-size: cover;
  //background-position: center;

  //background-image: url("/newassets/photo hands.png");
}

.HomeContent {

  width: 100%;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: 1921px) {
    gap: 1.042vw; // 20px
  }


  .commentCouple1Img {
    width: 299.01px;
    border-radius: 16px;
    margin-top: 16px;

    @media screen and (min-width: 1920px) {
      width: 21.042vw; // 404px
    }
  }

  .commentCouple1P {
    width: 299px;
    text-align: justify;
    padding-right: 50px;
    padding-left: 7px;
    padding-bottom: 10px;
    border-bottom: 1px solid #281B24;

    @media screen and (min-width: 1920px) {
      width: 21.042vw; // 404px
      padding-right: 2.604vw; //50px;
    }
  }

  .commentCouple2Img {
    padding-bottom: 16px;
    border-bottom: 1px solid #281B24;
    width: 224px;
    @media screen and (min-width: 1920px) {
      width: 15.833vw; // 304px
    }

    img {
      height: 307px;
      border-radius: 16px;

      @media screen and (min-width: 1920px) {
        height: 38vh; //421px;
      }
    }
  }

  .commentCouple2Name {
    padding-top: 8px;
    //border-top: 1px solid #281B24;
    width: 230px;

    @media screen and (min-width: 1920px) {
      width: 16.302vw; //313px;
    }
  }

  .commentCouple2P {
    width: 224px;
    text-align: justify;
    padding-bottom: 10px;

    @media screen and (min-width: 1920px) {
      width: 15.833vw; // 304px
    }

    &:after {
      mix-blend-mode: difference;
    }
  }


  h1 {
    margin: 0 auto 8px auto;

    width: 290px;
    height: auto;

    text-align: center;
    color: #281B24;

    @media screen and (min-width: 720px) {
      width: 90%;
    }

    @media screen and (min-width: 1024px) {
      width: 90%;
    }

    @media screen and (min-width: 1440px) {
      width: 100%;
    }

    @media screen and (min-width: 1920px) {
      width: 100%;
    }

  }
  h3 {
    margin: 0 auto 8px auto;

    width: 270px;

    text-align: center;

    color: #281B24;

    @media screen and (min-width: 520px) {
      width: 327px;
    }

    @media screen and (min-width: 720px) {
      width: 347px;
    }

    @media screen and (min-width: 1024px) {
      width: 90%;
    }

    @media screen and (min-width: 1440px) {
      width: 90%;
    }

    @media screen and (min-width: 1920px) {
      width: 60%;
    }
  }

  a {

    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 96px;
    gap: 10px;

    width: 287px;
    height: 48px;
    //left: 44px;
    //top: 425px;

    background: #F9AC80;
    border-radius: 8px;

    &:hover {
      background: #63435B;
      span {
        color: #F4F3F4 !important;
      }
    }

    @media screen and (min-width: 1921px) {
      width: 14.948vw;
      height: 2.500vw;
    }

  }

  span {
    //width: 130px;
    //height: 32px;

    color: #281B24;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
